const PubSub = require('tscom-pubsub');

const AUTH_TYPE = require('./../constants').AUTH_TYPE;


export const getUserDetails = () => {
  return window.nbc.identity.getUserDetails();
}

export const healthCheck = () => {
  return window.nbc.identity.healthCheck();
}

export const isInitialized = () => {
  return window.nbc.identity.status === 'initialized';
}

export const unauthenticate = () => {
  window.nbc.identity.unauthenticate();
}

export const initialize = ({ env, authModel, timeout}) => {
  if( window.nbc.identity.status === 'uninitialized' ) {
    window.nbc.identity.initialize('pccaweb', {
      debug: env !== 'production',
      env: env,
      useBaseSdkLocation: true,
      referringPage: window.location.href,
      registrationReferrer: document.title,
      version: '2'
    });

    if( !authModel.get('isAuthorized') || authModel.get('authType') === AUTH_TYPE.IDENTITY) {
      window.nbc.identity.on('profile', (profile) => {
        if( profile ) {
          authModel.setUpIdentityUser(profile);
        }
      });

      window.nbc.identity.on('windowState', (state) => {
        PubSub.trigger('identityWindowState', state);
      });
    }
  }

  return new Promise(( resolve, reject ) => {
    
    if( window.nbc.identity.status === 'initialized' ) {
      resolve();
    } else {

      const handle = setTimeout(() => {
        reject();
      }, timeout);

      window.nbc.identity.on('status', (value) => {
        if( value === 'initialized' ) {
          clearTimeout( handle );
          resolve();
        }
      });
    }
  });
}

export const launch = () => {
  window.nbc.identity.launch({
    registrationReferrer: "None",
    defaultPage: "logIn"
  });
}
