const TRUTHY_VALUES = [1, true, '1', 'enabled', 'k', 'sure', 'true', 'ya', 'yeah', 'yee', 'yes', 'yup'];
const { DEVICE, VOTE_WINDOW_TIMEZONE } = require('./../constants');

module.exports = {
  isOneTrustCategoryAllowed: function(categories) {
    if (!window.OnetrustActiveGroups || !categories) return true;

    var cookieIds = categories.split(',').map(c => c.trim());
    var testString = cookieIds.reduce((acc, str) => acc + `(?=.*?,${str},)`, '')
    var pattern = new RegExp(testString);

    return pattern.test(window.OnetrustActiveGroups);
  },

  isTrue: function (value) {
    return TRUTHY_VALUES.indexOf(value) >= 0;
  },

  delimitedStringToArray: function (str, delimiter) {
    delimiter = delimiter || ',';

    if (!str) return [];

    //Split the string on the delimiter into an array.
    var split = str.split(delimiter);

    //Trim each element.
    var trimmed = split.map(function (elem) {
      return elem.trim();
    });

    //Remove any empty elements.
    var filtered = trimmed.filter(function (elem) {
      return !!elem;
    });

    return filtered;
  },

  facebookShare: function(url) {
    const shareUrl = new URL('https://www.facebook.com/sharer/sharer.php');
    shareUrl.searchParams.append('u', url);
  
    window.open(shareUrl.toString(), 'popup', 'width=600, height=400, scrollbars=no');
  },
  
  isMobile: function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },

  getDevice: function () {
    if (/android|Android/i.test(navigator.userAgent)) {
      return DEVICE.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // iPad Pro does not have iPad in userAgent. Instead, check for Mac and ontouchend
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) || 
        (navigator.userAgent.includes("Mac") && "ontouchend" in document) 
        && !window.MSStream) {
      return DEVICE.IOS;
    }

    return DEVICE.DESKTOP;
  },

  getPlatform: function() {
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|SamsungBrowser/i.test(navigator.userAgent);

    // PCA-728 - feature detect as a workaround bc iPadOS ua for iOS 13 includes 'Macintosh' instead of iPad
    var isMacMobile = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;


    if (isMobile || isMacMobile) {
      var isPortrait = (window.innerHeight > window.innerWidth);
      var isTablet = (isPortrait && window.innerWidth > 600) || (!isPortrait && window.innerWidth > 1000);

      return isTablet? DEVICE.TABLET : DEVICE.MOBILE;
    } else {
      return DEVICE.DESKTOP;
    }
  },

  isNewDay: function( previousTimestamp, currentTimestamp ) {
    const current = new Date( currentTimestamp ).toLocaleDateString('en-US', { timeZone: VOTE_WINDOW_TIMEZONE });
    const previous = new Date( previousTimestamp ).toLocaleDateString('en-US', { timeZone: VOTE_WINDOW_TIMEZONE });

    return current !== previous;
  }
};