const ModelCms = require('@telescope/tscom-cms');

module.exports = ModelCms.extend({
  defaults: Object.assign({
    dateHeader: null
  }, ModelCms.prototype.defaults),

  sync: function(crud, model, options) {
    const xhr = ModelCms.prototype.sync.call( this, crud, model, options );

    return xhr.then(function() {
      this.set({
        dateHeader: xhr.getResponseHeader('Date')
      });
    }.bind(this));
  }

});
