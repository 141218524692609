const Backbone = require("backbone");
const CONSTANTS = require('./../constants');
const LOCAL_STORAGE_PREFIX = CONSTANTS.LOCAL_STORAGE_PREFIX;
const IDENTITY = CONSTANTS.AUTH_TYPE.IDENTITY;
const EMAIL = CONSTANTS.AUTH_TYPE.EMAIL;
const { isNewDay, isTrue } = require('./../util/helpers');

module.exports = Backbone.Model.extend({
  defaults: {
    authType: null,
    isAuthorized: false,
    registrationMethod: '',
    userName: null,
    userID: null,
    optins: {},
    initialized: false,
    useFallback: false
  },

  storageKey: btoa(LOCAL_STORAGE_PREFIX + '_user'),
  /**
   *
   */
  initialize: function (attributes, options) {
    this.controller = options.controller;
    this.cms = this.controller.Models.Cms;

    this.loadUser();
  },
  /**
   *
   */
  setupEmailUser: function (data) {
    this.set({
      authType: EMAIL,
      userName: data.email,
      userID: data.email,
      isAuthorized: true,
      optins: data.optins || {}
    });

    this.saveLocalStorage();
  },

  setUpIdentityUser: function( data ) {
    const optins = {
      'optin_opry': isTrue( data.optInAgreementIdentifiers.includes('optin_opry') )
    };

    let registrationMethod = '';
    if( data.appleId ) {
      registrationMethod = 'apple';
    } else if( data.googleId ) {
      registrationMethod = 'google';
    }

    this.set({
      authType: IDENTITY,
      email: data.mail,
      userName: data.userName,
      userID: data._id,
      registrationMethod: registrationMethod,
      isAuthorized: true,
      optins: optins
    });

    this.saveLocalStorage();
  },

  saveLocalStorage: function() {
    const currentDateTime = this.cms.get('dateHeader');

    try {
      switch( this.get('authType') ) {
      case EMAIL:
        localStorage.setItem( this.storageKey, btoa(JSON.stringify({
          authType: EMAIL,
          userName: this.get('userName'),
          userID: this.get('userID'),
          loginTime: currentDateTime
        })));
        break;
      case IDENTITY:
        localStorage.setItem( this.storageKey, btoa(JSON.stringify({
          email: this.get('email'),
          userName: this.get('userName'),
          userID: this.get('userID'),
          authType: IDENTITY,
          loginTime: currentDateTime,
          registrationMethod: this.get('registrationMethod')
        })));
        break;
      }
    } catch( e ) {
      // Do nothing
    }

  },

  clearLocalStorage() {
    localStorage.removeItem( this.storageKey );
  },

  loadUser: function() {
    try {
      const value = localStorage.getItem( this.storageKey );

      if( value ) {
        const json = atob( value );
        const user = JSON.parse( json );

        if( user.authType === EMAIL ) {
          const previousTimestamp = user.loginTime;
          const currentTimestamp = this.cms.get('dateHeader');

          if( isNewDay( previousTimestamp, currentTimestamp ) ) {
            this.clearLocalStorage();
            return;
          }
        }

        this.set( {
          ...user,
          isAuthorized: true,
          initialized: true
        });
      }
    } catch ( e ) {
      // Do nothing
    }
  },

  isInitialized: function() {
    if( !this.get('initialized') ) {
      return new Promise( (resolve) => {
        this.listenToOnce( this, 'change:initialized', () => {
          resolve();
        });
      });
    }

    return Promise.resolve();
  },

  deauthorize: function () {
    const initialized = this.get('initialized');
    localStorage.removeItem( this.storageKey );
    this.clear().set(Object.assign({}, this.defaults, { initialized: initialized }));
  }
});
